import React, {useState} from "react"
import { parse } from "csv-parse";
import JSZip from 'jszip';
import {Storage} from '@google-cloud/storage';
import { saveAs } from 'file-saver';
import CreateCertificate from "../functions/createCertificate";
import createBody from "../functions/createBody";
import { Button, ButtonGroup } from '@chakra-ui/react'
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import * as dotenv from 'dotenv'
import getZip from "../functions/getZip";
dotenv.config()

const storage = new Storage({keyFilename: process.env.GOOGLE_APPLICATION_CREDENTIALS});

const bucketName = 'solid-certificate-v1.appspot.com';

export default function BatchRequest(){

    const [csvFile, setCsvFile] = useState(null);
    const [csvData, setCsvData] = useState([]);
    const [nbRequest, setNbRequest] = useState(null);
    const [reqSended, setReqSended] = useState(0);
    const [csvRespond, setCsvRespond] = useState([]);
    const [reqPercent, setReqPercent] = useState(0);

    const handleFileUpload = (event) => {
        setCsvFile(event.target.files[0]);
    };

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    
    const handleFileParse = async (results) => {
        console.log(results)
        if (results) {
            setCsvData(results);
            setNbRequest(results.length - 2);
            console.log(results.length - 2);
            for (let i = 2; i < results.length; i++) {
                const Partner = results[i][0];
                const year = results[i][1];
                const nftName = results[i][2];
                const nftCollection = results[i][3];
                const nftId = results[i][4];
                const nftContract = results[i][5];
                const nftOwner = results[i][6];
                const typeBuild = results[i][7];
                const typeHost = results[i][8];
                const SolidDimensions = results[i][9];
                const SolidFraming = results[i][10];
                const SolidPrintLocation = results[i][11];
                const SolidMaterial = results[i][12];
                const OperationLead = results[i][13];
                const Production = results[i][14];
                const body = createBody(
                    Partner, year, nftName, nftCollection, nftId, 
                    nftContract, nftOwner, typeBuild, typeHost, 
                    SolidDimensions, SolidFraming, SolidPrintLocation, 
                    SolidMaterial, OperationLead, Production
                );
                console.log(body)
                const res = await CreateCertificate(process.env.apiKey, body);
                setReqSended(prevState => prevState + 1);

                const completedPercentage = (i / (results.length - 1)) * 100;
                setReqPercent(completedPercentage);
                console.log(completedPercentage);

                setCsvRespond(prevState => [...prevState, res])
                await sleep(8000)
            }
        }
        else{
            console.log("problem file");
        }
    };
      

    const handleFileSubmit = (event) => {
        event.preventDefault();
        if (csvFile) {
        const reader = new FileReader();
        reader.readAsText(csvFile);
        reader.onload = () => {
            parse(reader.result, {}, (err, results) => {
            if (err) {
                console.error(err);
            } else {
                handleFileParse(results);
            }
            });
        };
        }
    };

    const downloadImagesAsZip = async (imageLinks) => {
        await sleep(2000);
        const zip = await getZip(imageLinks);
        if (zip) {
            saveAs(zip, 'certificates.zip');
        } else {
            console.error('Failed to download the zip file.');
        }
    };
    


    return (
        <div className="container">
          <form className="form" onSubmit={handleFileSubmit}>
            <input className="input" type="file" onChange={handleFileUpload} />
            <Button color='#A0AEC0' textColor="#171923" type="submit">Envoyer vers le serveur</Button>
            <a href="./CSV_Exemple.csv" download>
                <Button color='#A0AEC0' textColor="#171923">Telecharger le CSV type</Button>
            </a>
            <div className="data-container">
                <div className="data-left">
                    <p>{nbRequest && <span>You have sent {nbRequest} Solid request to the server</span>}</p>
                    <p>{nbRequest && <span>{reqSended}/{nbRequest} sended to the server - {reqPercent}% completed</span>}</p>
                    <p className="center" >{nbRequest && <span >DO NOT LEAVE THE PAGE</span>}</p>
                </div>  
                {nbRequest && 
                    (
                        <div className="data-middle">
                            <CircularProgress value={reqPercent} color='green.400' textColor="white" fontWeight="bold">
                                <CircularProgressLabel>{reqPercent}%</CircularProgressLabel>
                            </CircularProgress>
                        </div>
                    )
                }
                <div className="response array data-right">
                    <ul>
                        {csvRespond.map((x) => { 
                            return (
                                <li key={x}>Lien image de {x.split("/")[6].split("-")[0]}: <a href={x} download target="_blank"> {x.split("/")[6]}</a></li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            {reqSended==nbRequest && 
                (
                    <div>
                        {/* Render the images or other elements here */}
                        <Button textColor="black" onClick={() => downloadImagesAsZip(csvRespond)}>Download Images as Zip</Button>
                    </div>
                )
            }
          </form>
        </div>
    );
}