export default function CreateBody(
    Partner, year, nftName, nftCollection, 
    nftId, nftContract, nftOwner, typeBuild, 
    typeHost, SolidDimensions, SolidFraming, 
    SolidPrintLocation, SolidMaterial, OperationLead, Production
){
    const requestBody = {
        Partner: Partner,
        year: "2023",
        nftName: nftName,
        nftCollection: nftCollection,
        nftId: nftId,
        nftContract: nftContract,
        nftOwner: nftOwner,
        typeBuild: typeBuild,
        typeHost: "gcloud",
        SolidDimensions: SolidDimensions,
        SolidFraming: SolidFraming,
        SolidPrintLocation: "Paris",
        SolidMaterial: "Aluminum",
        OperationLead: OperationLead,
        Production: Production,
    };
    return requestBody;
}