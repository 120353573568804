export default async function getZip(images) {
  const requestBody = { images };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Update the Content-Type header
    },
    body: JSON.stringify(requestBody), // Send the JSON string as the body
  };
  const response = await fetch('https://solid-certificate-v1.oa.r.appspot.com/batch/zip', options);
  console.log('Response status:', response.status);
  if (response.status !== 200) {
    console.log('Response JSON:', await response.json());
  } else {
    const blob = await response.blob();
    return blob;
  }
}
