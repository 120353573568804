import React, { useState } from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react'
import './App.css'
import BatchRequest from './components/BatchRequest';
import FormsRequest from './components/FormsRequest';

export default function App() {

    const [window, setWindow] = useState("");

    const handleBatchClick = () => {
        setWindow("batch");
    }

    const handleFormsClick = () => {
        setWindow("forms");
    }

    return (
        <div className='app-container'>
            <div className='buttons'>
                <Button colorScheme='blue' onClick={handleBatchClick}>Batch Request</Button>
                <Button colorScheme='teal' onClick={handleFormsClick}>Forms Request</Button>
            </div>
            {window === "batch" ? <BatchRequest /> : <FormsRequest />}
        </div>
    )
}