export default async function CreateCertificate(apiKey, requestBody) {
    const formData = new FormData();
    for (const key in requestBody) {
        formData.append(key, requestBody[key]);
    }
    console.log(formData);
    const res = await fetch('https://solid-certificate-v1.oa.r.appspot.com/certificate/create', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-API-Key': apiKey,
        },
    body: new URLSearchParams(formData)
    })
    const response = await res.json();
    return response.url_image;
}