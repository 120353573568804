import React, { useState } from 'react';
import CreateBody from '../functions/createBody';
import createBody from '../functions/createBody';

import CreateCertificate from '../functions/createCertificate';

import { useToast } from '@chakra-ui/react'
import { Button, ButtonGroup } from '@chakra-ui/react'

import * as dotenv from 'dotenv'
dotenv.config()

const FormsRequest = () => {
    const [numForms, setNumForms] = useState(1);
    const [response, setServerResponse] = useState(null);
    const [imgurl, setImgurl] = useState(null);

    const handleNumFormsChange = (event) => {
        setNumForms(parseInt(event.target.value, 10));
    };

    const clearInput = () => {
        setPartner('');
        setYear('');
        setNftName('');
        setNftCollection('');
        setNftId('');
        setNftContract('');
        setNftOwner('');
        setTypeBuild('');
        setTypeHost('');
        setSolidDimensions('');
        setSolidFraming('');
        setSolidPrintLocation('');
        setSolidMaterial('');
        setOperationLead('');
        setProduction('');
    }
    function handleDownloadClick() {
        if (imgurl) {
          const downloadLink = document.createElement('a');
          downloadLink.href = imgurl;
          downloadLink.download = `certificate-${response.id_commande}.png`;
          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
    const handleFormSubmit = async (
        Partner,year,nftName,nftCollection,nftId,nftContract,nftOwner,typeBuild,
        typeHost,SolidDimensions,SolidFraming,SolidPrintLocation,SolidMaterial,OperationLead,Production
        ) => 
    {
        const body = CreateBody(Partner, year, nftName, nftCollection, 
            nftId, nftContract, nftOwner, typeBuild, 
            typeHost, SolidDimensions, SolidFraming, 
            SolidPrintLocation, SolidMaterial, OperationLead, Production
        );
        const responseserver = await CreateCertificate(process.env.apiKey, body);
        const res = await responseserver.json();
        console.log(res);
        // set the server response as state after 8 seconds
        setTimeout(() => {
            setServerResponse(res);
        }, 10000);

        setImgurl(res.url_image);
    };
    
    const [Partner, setPartner] = useState('');
    const [year, setYear] = useState('');
    const [nftName, setNftName] = useState('');
    const [nftCollection, setNftCollection] = useState('');
    const [nftId, setNftId] = useState('');
    const [nftContract, setNftContract] = useState('');
    const [nftOwner, setNftOwner] = useState('');
    const [typeBuild, setTypeBuild] = useState('');
    const [typeHost, setTypeHost] = useState('');
    const [SolidDimensions, setSolidDimensions] = useState('');
    const [SolidFraming, setSolidFraming] = useState('');
    const [SolidPrintLocation, setSolidPrintLocation] = useState('');
    const [SolidMaterial, setSolidMaterial] = useState('');
    const [OperationLead, setOperationLead] = useState('');
    const [Production, setProduction] = useState('');

    function ButtonSubmit() {
        const toast = useToast()
        return (
          <Button
            onClick={() =>
              toast({
                title: 'Request sended',
                description: "We've send the request to the server",
                status: 'success',
                duration: 3500,
                isClosable: true,
              })
            }
            type="submit" 
            className='form-button'
            colorScheme='blue'
          >
            Send SolidCertificate
          </Button>
        )
      }

    const renderForms = () => {
        const forms = [];
        for (let i = 0; i < numForms; i++) {
        forms.push(
            <form key={i} className="form" onSubmit={(event) => {
                event.preventDefault();
                handleFormSubmit(
                    Partner,
                    year,
                    nftName,
                    nftCollection,
                    nftId,
                    nftContract,
                    nftOwner,
                    typeBuild,
                    typeHost,
                    SolidDimensions,
                    SolidFraming,
                    SolidPrintLocation,
                    SolidMaterial,
                    OperationLead,
                    Production
                );
            }}>
                <h3>Formulaire {i + 1}</h3>
                <label className='form-label'>
                    Partner:
                    <input className='form-input' type="text" value={Partner} onChange={(event) => setPartner(event.target.value)} />
                </label>
                <label className='form-label'>
                    Year:
                    <input className='form-input' type="text" value="2023" disabled />
                </label>
                <label className='form-label'>
                    NFT Name:
                    <input className='form-input' type="text" value={nftName} onChange={(event) => setNftName(event.target.value)} />
                </label>
                <label className='form-label'>
                    NFT Collection:
                    <input className='form-input' type="text" value={nftCollection} onChange={(event) => setNftCollection(event.target.value)} />
                </label>
                <label className='form-label'>
                    NFT ID:
                    <input className='form-input' type="text" value={nftId} onChange={(event) => setNftId(event.target.value)} />
                </label>
                <label className='form-label'>
                    NFT Contract:
                    <input className='form-input' type="text" value={nftContract} onChange={(event) => setNftContract(event.target.value)} />
                </label>
                <label className='form-label'>
                    NFT Owner:
                    <input className='form-input' type="text" value={nftOwner} onChange={(event) => setNftOwner(event.target.value)} />
                </label>
                <label className='form-label'>
                    Type Build:
                    <select
                        className='form-input'
                        value={typeBuild}
                        onChange={(event) => setTypeBuild(event.target.value)}
                    >
                        <option value="nft-background">nft-background</option>
                        <option value="nft-background-signature">nft-background-signature</option>
                        <option value="black-border">black-border</option>
                        <option value="gradient">gradient</option>
                    </select>
                </label>
                <label className='form-label'>
                    Type Host:
                    <input className='form-input' type="text" value="gcloud" disabled />
                </label>
                <label className='form-label'>
                    Solid Dimensions:
                    <input className='form-input' type="text" value={SolidDimensions} onChange={(event) => setSolidDimensions(event.target.value)} />
                </label>
                <label className='form-label'>
                    Solid Framing:
                    <input className='form-input' type="text" value={SolidFraming} onChange={(event) => setSolidFraming(event.target.value)} />
                </label>
                <label>
                    Solid Print Location:
                    <input className='form-input' type="text" value="Paris" disabled />
                </label>
                <label className='form-label'>
                    Solid Material:
                    <input className='form-input' type="text" value="Aluminium" disabled />
                </label>
                <label className='form-label'>
                    Operation Lead:
                    <input className='form-input' type="text" value={OperationLead} onChange={(event) => setOperationLead(event.target.value)} />
                </label>
                <label className='form-label'>
                    Production:
                    <input className='form-input' type="text" value={Production} onChange={(event) => setProduction(event.target.value)} />
                </label>
                <ButtonSubmit/>
                {response && (
                    <div>
                    <p>URL Image : <a href={response.url_image} download target="_blank">Download Image</a></p>
                    <p>ID Commande: {response.id_commande}</p>
                    </div>
                )}
            </form>
        );
        }
        return forms;
    };
  

    return (
        <div className='forms-container'>
            <label className='forms-question'>
                Combien de certificat voulez vous creer ? (Plus de 10 go batch request !)
                <input type="number" value={numForms} onChange={handleNumFormsChange} />
            </label>
            <div className='forms'>
                {renderForms()}
            </div>
            
        </div>
    );
};

export default FormsRequest;
