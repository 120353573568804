{
  "name": "@google-cloud/storage",
  "description": "Cloud Storage Client Library for Node.js",
  "version": "6.9.5",
  "license": "Apache-2.0",
  "author": "Google Inc.",
  "engines": {
    "node": ">=12"
  },
  "repository": "googleapis/nodejs-storage",
  "main": "./build/src/index.js",
  "types": "./build/src/index.d.ts",
  "files": [
    "build/src",
    "!build/src/**/*.map"
  ],
  "keywords": [
    "google apis client",
    "google api client",
    "google apis",
    "google api",
    "google",
    "google cloud platform",
    "google cloud",
    "cloud",
    "google storage",
    "storage"
  ],
  "scripts": {
    "predocs": "npm run compile",
    "docs": "jsdoc -c .jsdoc.js",
    "system-test": "mocha build/system-test --timeout 600000 --exit",
    "conformance-test": "mocha --parallel build/conformance-test/ --require build/conformance-test/globalHooks.js",
    "preconformance-test": "npm run compile",
    "presystem-test": "npm run compile",
    "test": "c8 mocha build/test",
    "pretest": "npm run compile",
    "lint": "gts check",
    "samples-test": "npm link && cd samples/ && npm link ../ && npm test && cd ../",
    "all-test": "npm test && npm run system-test && npm run samples-test",
    "check": "gts check",
    "clean": "gts clean",
    "compile": "tsc -p .",
    "fix": "gts fix",
    "prepare": "npm run compile",
    "docs-test": "linkinator docs",
    "predocs-test": "npm run docs",
    "benchwrapper": "node bin/benchwrapper.js",
    "prelint": "cd samples; npm link ../; npm install",
    "precompile": "gts clean"
  },
  "dependencies": {
    "@google-cloud/paginator": "^3.0.7",
    "@google-cloud/projectify": "^3.0.0",
    "@google-cloud/promisify": "^3.0.0",
    "abort-controller": "^3.0.0",
    "async-retry": "^1.3.3",
    "compressible": "^2.0.12",
    "duplexify": "^4.0.0",
    "ent": "^2.2.0",
    "extend": "^3.0.2",
    "gaxios": "^5.0.0",
    "google-auth-library": "^8.0.1",
    "mime": "^3.0.0",
    "mime-types": "^2.0.8",
    "p-limit": "^3.0.1",
    "retry-request": "^5.0.0",
    "teeny-request": "^8.0.0",
    "uuid": "^8.0.0"
  },
  "devDependencies": {
    "@google-cloud/pubsub": "^3.0.0",
    "@grpc/grpc-js": "^1.0.3",
    "@grpc/proto-loader": "^0.7.0",
    "@types/async-retry": "^1.4.3",
    "@types/compressible": "^2.0.0",
    "@types/ent": "^2.2.1",
    "@types/extend": "^3.0.0",
    "@types/mime": "^3.0.0",
    "@types/mime-types": "^2.1.0",
    "@types/mocha": "^9.1.1",
    "@types/mockery": "^1.4.29",
    "@types/node": "^18.0.0",
    "@types/node-fetch": "^2.1.3",
    "@types/proxyquire": "^1.3.28",
    "@types/request": "^2.48.4",
    "@types/sinon": "^10.0.0",
    "@types/tmp": "0.2.3",
    "@types/uuid": "^8.0.0",
    "@types/yargs": "^17.0.10",
    "c8": "^7.0.0",
    "form-data": "^4.0.0",
    "gts": "^3.1.0",
    "jsdoc": "^4.0.0",
    "jsdoc-fresh": "^2.0.0",
    "jsdoc-region-tag": "^2.0.0",
    "linkinator": "^4.0.0",
    "mocha": "^9.2.2",
    "mockery": "^2.1.0",
    "nock": "~13.3.0",
    "node-fetch": "^2.6.7",
    "proxyquire": "^2.1.3",
    "sinon": "^15.0.0",
    "tmp": "^0.2.0",
    "typescript": "^4.6.4",
    "yargs": "^17.3.1"
  }
}
